<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <alert-error
      :show="isError"
      :msg="errorMessage"
      :close="closeAlert"
    ></alert-error>
    <alert-confirm
      :show="isDelete"
      title="Remove Confirmation"
      subTitle="Are you sure you want to remove this Symptomatic Areas? This action will remove content in both Thai and English."
      :close="closeDeletePopup"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDelete"
      icon=""
    ></alert-confirm>
    <alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></alert-confirm>
    <v-alert-success :show="isSuccess" :close="closeAlert"></v-alert-success>
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px;">
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Symptomatic Areas
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermDelete()"
            :show-preview="false"
            :show-save="!disabledEdit"
            :on-remove="onRemove"
            :on-cancel="showCancelPopup"
            :on-save="onSave"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            Symptomatic Areas Information
          </h6>
          <v-spacer></v-spacer>
          <p class="edit-field-label sub-body-bold mr-4" t-data="status-title">
            Status
          </p>
          <v-switch
            color="primary"
            v-model="symptomaticAreasObject.status"
            :disabled="disabledEdit"
            :false-value="1"
            :true-value="0"
            class="mb-0 pb-0"
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-0">
          <v-row align="start" justify="start" class="mt-0">
            <v-col cols="6" class="py-0">
              <p
                class="body-regular warning-content mb-6"
                t-data="warning-content"
              >
                All fields are required.
              </p>
            </v-col>
          </v-row>
          <v-row align="start" justify="start" class="mt-0 px-2 mb-5">
            <v-col cols="6" t-data="info-input">
              <single-selected-branch
                outlined
                required
                dense
                title="Branch"
                :isDisabled="disabledEdit"
                :branch="branches"
                :selectedBranch="selectedBranch"
                :syncData="changedSelectedBranch"
                :isEditMode="isEditMode"
                v-if="!isLoading"
              ></single-selected-branch>
            </v-col>
          </v-row>
          <v-card class="border-card">
            <v-card-text>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6">
                  <h4 class="mb-6 card-title" t-data="info-title">
                    Thai
                  </h4>
                </v-col>
                <v-col cols="2">
                  <h4 class="mb-6 card-title" t-data="info-title">
                    English
                  </h4>
                </v-col>
                <v-col t-data="checkbox-duplicate">
                  <v-checkbox
                    v-model="duplicateContent"
                    :disabled="disabledEdit"
                    hide-details
                    label="Duplicate Thai content"
                    color="secondary"
                    class="check-box-text body-regular mt-0 pt-0"
                    @click="duplicateThaiContent"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Name
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    :rules="nameThRules"
                    v-model="symptomaticAreasObject.contents[0].name"
                    :placeholder="disabledEdit ? '' : 'Input Name'"
                    @input="inputNameTh"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Name
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    :rules="nameEnRules"
                    v-model="symptomaticAreasObject.contents[1].name"
                    :placeholder="disabledEdit ? '' : 'Input Name'"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row class="d-flex align-start mr-0 mb-3" style="margin-left: 1px;">
      <created-detail v-if="isEditMode" :data-object="symptomaticAreasObject" />
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import AlertConfirm from '@/components/AlertConfirm'
import AlertError from '@/components/AlertError'
import AlertSuccess from '@/components/AlertSuccess'
import CreatedDetail from '@/components/CreatedDetail'
import EditButtons from '@/components/EditButtons'
import PopupLoading from '@/components/PopupLoading'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import {actionEnum} from '@/constants/constants'
import {hasPermissionsByOrgs} from '@/helper/helper'

export default {
  components: {
    'v-alert-success': AlertSuccess,
    'v-popup-loading': PopupLoading,
    AlertError,
    AlertConfirm,
    CreatedDetail,
    EditButtons,
    SingleSelectedBranch
  },
  name: 'Edit',
  data() {
    return {
      isEditMode: false,
      isCancel: false,
      isError: false,
      isSuccess: false,
      duplicateContent: false,
      nameThRules: [v => !!v || 'Name is required'],
      nameEnRules: [v => !!v || 'Name is required']
    }
  },

  computed: {
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditSymptomatic', [
          this.symptomaticAreasObject.org_codename
        ])
      )
    },
    branches() {
      return this.orgObject.map(o => o.value)
    },
    selectedBranch() {
      let selectedBranch = null
      if (this.symptomaticAreasObject.org_codename !== null) {
        selectedBranch = this.symptomaticAreasObject.org_codename
      }
      return selectedBranch
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {text: 'Department Management', disabled: true, link: false},
        {
          text: 'Symptomatic Areas',
          link: true,
          to: '/symptomatic-areas'
        },
        {
          text: this.textMode,
          disabled: true,
          href: '/'
        }
      ]
    },
    ...mapState('symptomaticAreas', [
      'errorMessage',
      'isDelete',
      'isLoading',
      'orgObject',
      'symptomaticAreasObject',
    ])
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
    this.resetList()
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(this.isEditMode)

    if (this.isEditMode) {
      await this.setSymptomaticAreasId(this.$route.params.id)
      // scroll to top
      window.scrollTo(0, 0)
    } else {
      this.resetState()
    }

    const isViewOnly =
      this.isEditMode &&
      !hasPermissionsByOrgs('CanEditSymptomatic', [
        this.symptomaticAreasObject.org_codename
      ])
    await this.setOrgInfo(isViewOnly)
  },
  methods: {
    hasPermDelete() {
      return hasPermissionsByOrgs('CanDeleteSymptomatic', [
        this.symptomaticAreasObject.org_codename
      ])
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({
        name: 'symptomatic-areas-list'
      })
    },
    async onRemove() {
      this.setDeleteId(this.$route.params.id)
      this.setDeletePopup(true)
    },
    changedSelectedBranch(value) {
      if (value.length > 0) {
        this.symptomaticAreasObject.org_codename = value[0]
      }
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    async confirmDelete() {
      const isSuccess = await this.deletePkg()
      if (isSuccess) {
        this.$router.push({
          name: 'symptomatic-areas-list',
          query: {isCreateSuccess: true, snackTitle: 'removed'}
        })
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    showFailSnackbar(action) {
      this.snackTitle = action
    },
    duplicateThaiContent() {
      if (this.duplicateContent) {
        this.symptomaticAreasObject.contents[1].name = this.symptomaticAreasObject.contents[0].name
      } else {
        this.symptomaticAreasObject.contents[1].name = null
      }
    },
    inputNameTh() {
      if (this.duplicateContent) {
        this.symptomaticAreasObject.contents[1].name = this.symptomaticAreasObject.contents[0].name
      }
    },
    async onSave() {
      if (this.validate()) {
        if (this.isEditMode) {
          const status = await this.updateSymptomaticAreas({
            id: this.$route.params.id,
            pkg: this.symptomaticAreasObject
          })
          if (status) {
            this.$router.push({
              name: 'symptomatic-areas-list',
              query: {isCreateSuccess: true, snackTitle: 'edited'}
            })
          } else {
            return (this.isError = true)
          }
        } else {
          const status = await this.create(this.symptomaticAreasObject)
          if (status) {
            this.$refs.form.resetValidation()
            this.resetState()
            this.$router.push({
              name: 'symptomatic-areas-list',
              query: {isCreateSuccess: true, snackTitle: 'created'}
            })
          } else {
            return (this.isError = true)
          }
        }
      }
    },
    closeAlert() {
      this.isError = false
      this.isSuccess = false
    },
    validate() {
      if (this.$refs.form.validate()) {
        return true
      } else {
        return false
      }
    },
    ...mapActions('symptomaticAreas', [
      'create',
      'deletePkg',
      'resetList',
      'resetState',
      'setDeleteId',
      'setDeletePopup',
      'setEditMode',
      'setOrgInfo',
      'setSymptomaticAreasId',
      'update',
      'updateSymptomaticAreas'
    ])
  }
}
</script>

<style scoped></style>
